<template>
  <v-container fluid>
    <contract-title :title="options.title.main" class="mb-2" />
    <contract-category @select-tab="selectCategory" />
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";

export default Vue.extend({
  name: "ContractItems",
  components: {
    ContractTitle: () => import("@/components/reference/ReferenceTitle.vue"),
    ContractCategory: () =>
      import("@/components/reference/contract/ContractCategory.vue")
  },
  computed: {
    options: get("reference/optionsContract")
  },
  data() {
    return {};
  },
  methods: {
    selectCategory(selectCategory) {
      this.facilityType = selectCategory;
    }
  },
  mounted() {}
});
</script>
